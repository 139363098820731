<template>
  <div>
    <CCard class="col-lg-6 col-sm-12">
      <CCardHeader>
        Bought History
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <div class="position-relative">
              <CDataTable
                hover
                small
                fixed
                table-filter
                items-per-page-select
                :items-per-page="15"
                :fields="boughtListFields"
                :items="boughtListItem"
                :noItemsView="{ noItems: 'No Item~' }"
                pagination
              >
                <template #date="{item}">
                  <td class="py-2">
                    <CCardText>{{ item.datetime * 1000 | dateFormatDate }}</CCardText>
                  </td>
                </template>
                <template #content="{item}">
                  <td class="py-2">
                    <CCardText>Bandwidth:{{item.content.bandwidth}}GB</CCardText>
                    <CCardText>Time duration:{{item.content.expire}}Days</CCardText>
                  </td>
                </template>
                <template #expire="{item}">
                  <td class="py-2">
                    <CCardText>{{parseInt(item.datetime)+parseInt(item.content.expire*86400) | dateFormatDate }}</CCardText>
                  </td>
                </template>
                <template #remark="{item}">
                  <td class="py-2">
                    <CCardText>{{item.content.content_extra}}</CCardText>
                  </td>
                </template>
              </CDataTable>
              <CElementCover
                :boundaries="[{ sides: ['top', 'left'], query: '.media-body' }]"
                :opacity="1"
                v-if="listLoading"
              >
                <h1 class="d-inline">Loading... </h1>
                <CSpinner size="2xl" color="success" />
              </CElementCover>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
export default {
  name: "BoughtHistory",
  data() {
    const boughtListFields = [
      { key: "date", label: "Purchase Date" },
      { key: "content", label: "Purchase Content" },
      { key: "expire", label: "Expire Date" },
      { key: "remark", label: "Extra Remark" }

    ];
    return {
      listLoading: true,
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      boughtListFields,
      boughtListItem: [],
      nodeInfoModal: false
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/account/bought/list", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.boughtListItem = response.data.boughtList.map(item => {
                let content = JSON.parse(item.shop.content)
                return {
                  ...item,
                  content
                };
              }
            );
            this.listLoading = false;
          }
        });
    }
  }
};
</script>