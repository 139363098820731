var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{staticClass:"col-lg-6 col-sm-12"},[_c('CCardHeader',[_vm._v(" Bought History ")]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"position-relative"},[_c('CDataTable',{attrs:{"hover":"","small":"","fixed":"","table-filter":"","items-per-page-select":"","items-per-page":15,"fields":_vm.boughtListFields,"items":_vm.boughtListItem,"noItemsView":{ noItems: 'No Item~' },"pagination":""},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CCardText',[_vm._v(_vm._s(_vm._f("dateFormatDate")(item.datetime * 1000)))])],1)]}},{key:"content",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CCardText',[_vm._v("Bandwidth:"+_vm._s(item.content.bandwidth)+"GB")]),_c('CCardText',[_vm._v("Time duration:"+_vm._s(item.content.expire)+"Days")])],1)]}},{key:"expire",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CCardText',[_vm._v(_vm._s(_vm._f("dateFormatDate")(parseInt(item.datetime)+parseInt(item.content.expire*86400))))])],1)]}},{key:"remark",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CCardText',[_vm._v(_vm._s(item.content.content_extra))])],1)]}}])}),(_vm.listLoading)?_c('CElementCover',{attrs:{"boundaries":[{ sides: ['top', 'left'], query: '.media-body' }],"opacity":1}},[_c('h1',{staticClass:"d-inline"},[_vm._v("Loading... ")]),_c('CSpinner',{attrs:{"size":"2xl","color":"success"}})],1):_vm._e()],1)])],1)],1)],1),_c('CToaster',{attrs:{"autohide":3000}},[_vm._l((_vm.fixedToasts),function(toast){return [_c('CToast',{key:'toast' + toast,attrs:{"show":true,"color":_vm.alertLevel,"header":"Alert"}},[_vm._v(" "+_vm._s(_vm.returnMsg)+" ")])]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }